import { campaignTypes } from "./actions";
import { getUrls } from "./selectors";
const initialState = {
  orgId: null,
  campaign_ticket_packages: [],
};

const campaignReducers = (state = initialState, action) => {
  switch (action.type) {
    case campaignTypes.CAMPAIGN_CLEAR:
      return { ...initialState };
    case campaignTypes.CAMPAIGN_FETCH_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case campaignTypes.CAMPAIGN_FETCH_NOT_FOUND:
      return {
        ...state,
        error: true,
        notFound: true,
      };
    case campaignTypes.CAMPAIGN_PROGRESS_FETCH_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case campaignTypes.CAMPAIGN_DONATION_GROUP_FETCH_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case campaignTypes.CAMPAIGN_DATA_FETCH_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

function getSocialMediaTxtSelector(
  state,
  withUrl = false,
  includeGreeting = false,
) {
  const { fullHomeUrlWithAffiliate: siteLink } = getUrls(state);
  const { name } = state.campaign;
  let text = includeGreeting ? `Hello,\n\n` : "";
  text = `${text}${name} is raising funds.\nPlease take a moment to check out their campaign page.\n`;
  if (withUrl) text = `${text}${siteLink}\n\n`;
  text = `${text}Your support will be greatly appreciated.`;
  return encodeURI(text);
}

export { campaignReducers, getSocialMediaTxtSelector };
