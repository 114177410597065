import React, { Fragment } from "react";
import {
  MenuItem,
  FormControlLabel,
  TextField as MuiTextField,
} from "@material-ui/core";
import { Field, useFormState } from "react-final-form";
import { TextField, Checkbox } from "final-form-material-ui";
import classnames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { donationActions, productActions } from "../../state";
import {
  ProductShippingInfo,
  AdditionalInfo,
  DinnerInfo,
  Tips,
  ProcessingFee,
  GiftCardInfo,
  CCEntryMethod,
  DonationMessage,
} from "..";
import {
  required,
  email,
  composeValidators,
  USZip,
} from "../../lib/validators";
import { isProductCampaign } from "../../lib";
import { nameTitles, stateOptions } from "../../lib/optionGenerators";
import { donationFormStyles } from "./DonationForm.styles";

function _CustomerInfo(props) {
  const {
    twoColLayout,
    campaign: {
      campaign_type_id,
      companyId,
      campaign_donation_group_id,
      // allowed_payment_plan: allowedPlan,
      hide_donations,
      show_volunteer_donations,
      canadianCampaign,
      use_tips,
      use_processing_fee,
    },
    setAdminVolunteerId,
    forVolunteer,
    adminIframe,
    forOrgIframe,
    authToken,
    iframeCanSubmitPledges,
  } = props;
  const { values } = useFormState();
  const isGivver = companyId === Number(process.env.REACT_APP_GIVVR_COMPANY_ID);
  const isProduct = isProductCampaign(campaign_type_id);
  const classes = donationFormStyles({ isGivver, twoColLayout, isProduct });
  const isDisplayingDonations = Boolean(
    !hide_donations ||
      (forVolunteer && show_volunteer_donations) ||
      campaign_donation_group_id,
  );

  return (
    <div className={classes.customerInfo}>
      {isProduct && <div className={classes.checkout}>Check out</div>}
      {!isProduct && <CCEntryMethod forOrgIframe={forOrgIframe} />}
      {/* PERSONAL INFO */}
      <div className={classes.formSubHeader}>Personal info</div>
      <div className={classes.inputGroup}>
        <div className={classes.splitInputSection}>
          {isGivver && (
            <Field
              name="title"
              label="Title"
              select
              required
              component={TextField}
              validate={required}
              className={classnames(classes.input, classes.titleInput)}
            >
              {nameTitles.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Field>
          )}
          <Field
            name="first_name"
            label="First name"
            required
            component={TextField}
            validate={required}
            className={classnames(classes.input, classes.firstNameInput)}
          />
        </div>
        <Field
          name="last_name"
          label="Last name"
          required
          component={TextField}
          validate={required}
          className={classes.input}
        />
      </div>
      <div className={classes.inputGroup}>
        <Field
          name="email"
          label="Email"
          required
          fullWidth={true}
          component={TextField}
          validate={composeValidators(required, email)}
          className={classes.input}
          type="email"
        />
        <Field
          name="phone"
          label="Phone"
          component={TextField}
          className={classes.input}
          type="phone"
        />
      </div>
      {isDisplayingDonations && (
        <Fragment>
          <Field
            name="display_name"
            label="Display name as (optional)"
            className={classnames(classes.input, classes.fullWidthInput)}
            render={({ input, ...rest }) => {
              return (
                <MuiTextField
                  {...rest}
                  {...input}
                  value={values.anonymous ? "Anonymous" : input.value}
                  onChange={e => input.onChange(e.target.value)}
                />
              );
            }}
          />
          <FormControlLabel
            className={classnames(classes.input, classes.anonymous)}
            control={
              <Field
                name="anonymous"
                component={Checkbox}
                color="primary"
                type="checkbox"
              />
            }
            label="Support anonymously"
          />
        </Fragment>
      )}
      {/* END PERSONAL INFO */}

      {/* BILLING INFO */}
      <div className={classes.formSubHeader}>Billing address</div>
      <div className={classes.inputGroup}>
        <div className={classes.splitInputSection}>
          <Field
            name="address"
            label="Address"
            required
            className={classnames(classes.input, classes.addressInput)}
            component={TextField}
            validate={required}
          />
          <Field
            name="apt"
            label="Apt"
            className={classnames(classes.input, classes.aptInput)}
            component={TextField}
          />
        </div>
        <Field
          name="city"
          label="City"
          required
          component={TextField}
          validate={required}
          className={classes.input}
        />
      </div>
      <div className={classes.inputGroup}>
        {isProduct && !canadianCampaign ? (
          <Field
            name="state"
            label="State"
            select
            required
            validate={required}
            component={TextField}
            className={classes.input}
          >
            {stateOptions.map(({ name, code }) => (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            ))}
          </Field>
        ) : (
          <Field
            name="state"
            label={canadianCampaign ? "Province" : "State"}
            required
            component={TextField}
            validate={required}
            className={classes.input}
          />
        )}
        <Field
          name="zip"
          label={canadianCampaign ? "Postal code" : "Zip"}
          required
          component={TextField}
          validate={
            isProduct && !canadianCampaign
              ? composeValidators(required, USZip)
              : required
          }
          className={classes.input}
        />
      </div>
      {/* END BILLING INFO */}

      <DinnerInfo />
      {isGivver && <DonationMessage formValues={values} />}
      <AdditionalInfo
        {...{
          isProduct,
          forOrgIframe,
          iframeCanSubmitPledges,
          isGivver,
          forVolunteer,
          adminIframe,
          setAdminVolunteerId,
          authToken,
          isDisplayingDonations,
          formValues: values,
        }}
      />

      {use_processing_fee && <ProcessingFee />}
      {!isProduct && use_tips && <Tips />}
      {isProduct && <ProductShippingInfo formValues={values} />}
      {isProduct && <GiftCardInfo />}
    </div>
  );
}

export const CustomerInfo = connect(
  state => {
    const {
      campaign,
      product: { selectedProducts, shipping_options },
      ui: { sessionEnded, affiliate },
      donation: { ccEntryMode },
    } = state;
    return {
      campaign,
      ccEntryMode,
      selectedProducts,
      shipping_options,
      sessionEnded,
      affiliate,
    };
  },
  dispatch => {
    return {
      actions: bindActionCreators(donationActions, dispatch),
      productActions: bindActionCreators(productActions, dispatch),
    };
  },
)(_CustomerInfo);
