import { makeStyles } from "@material-ui/core";

export const privacyStyles = makeStyles(theme => ({
  container: {
    width: "600px",
    maxWidth: "100%",
    height: "100%",
    borderRadius: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  content: {
    padding: "40px 40px 24px 40px",
    lineHeight: "1.42em !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "24px 16px",
      minHeight: "calc(100vh - 90px)",
    },
  },
  title: {
    fontSize: "32px",
    letterSpacing: "0.3px",
    marginBottom: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },
  close: {
    borderTop: "1px solid #222222",
    padding: "12px 0",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 0",
    },
  },
  button: {
    height: "40px",
    width: "160px",
    fontSize: "18px",
    fontWeight: 600,
    letterSpacing: "0.23px",
  },
}));
