import React, { useState, useEffect, Fragment } from "react";
import {
  Container,
  makeStyles,
  Button,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AddIcon from "@material-ui/icons/Add";
import { connect, useDispatch } from "react-redux";
import { useField } from "react-final-form";
import { formatCurrencyWithSymbol, formatMoneyInput } from "../../lib";
import { productActions } from "../../state";

export function _ProductDonation(props) {
  const {
    campaign: { allow_donations, allow_custom_amt, suggested_amounts },
  } = props;
  const classes = styles();
  const [hasSuggestions, setHasSuggestions] = useState(false);

  useEffect(() => {
    if (Array.isArray(suggested_amounts) && suggested_amounts.length) {
      setHasSuggestions(true);
    }
  }, [suggested_amounts]);

  if (!allow_donations || (!hasSuggestions && !allow_custom_amt)) {
    return <Fragment />;
  }
  return (
    <div className={classes.wrapper}>
      <Container>
        <div className={classes.header}>
          A donation is another great way to help!
        </div>

        {hasSuggestions && (
          <div className={classes.cardContainer}>
            {suggested_amounts.map(({ amount, text }, index) => {
              return <Card key={index} amount={amount} text={text} />;
            })}
            {allow_custom_amt && <CustomCard />}
          </div>
        )}

        {!hasSuggestions && allow_custom_amt && <CustomCard noSuggestions />}
      </Container>
    </div>
  );
}

export const ProductDonation = connect(state => {
  return { campaign: state.campaign };
})(_ProductDonation);

function Card(props) {
  const { amount, text } = props;
  const amountField = useField("amount").input;
  const customAmountField = useField("customAmount");
  const activeAmount = amountField.value;
  const isSelected = Number(amount) === Number(activeAmount);
  const classes = styles({ isSelected });
  const dispatch = useDispatch();

  function handleClick() {
    const newVal = isSelected ? null : amount;
    amountField.onChange(newVal);
    dispatch(productActions.setProductDonation(newVal));
    customAmountField.input.onChange("");
  }

  return (
    <div className={classes.card}>
      <div className={classes.suggestionAmount}>
        {formatCurrencyWithSymbol(amount)}
      </div>
      {text && <div className={classes.suggestionText}>{text}</div>}
      <Button
        color="primary"
        variant="contained"
        classes={{ label: classes.buttonLabel }}
        onClick={handleClick}
        className={classes.button}
        startIcon={isSelected ? <CheckCircleIcon /> : <AddIcon />}
      >
        {isSelected ? "Added" : "Add"}
      </Button>
    </div>
  );
}

function CustomCard({ noSuggestions }) {
  const customAmountField = useField("customAmount").input;
  const customAmount = customAmountField.value;
  const amountField = useField("amount");
  const isSelected = Boolean(customAmount);
  const classes = styles({ isSelected });
  const dispatch = useDispatch();

  const onChange = e => {
    const formattedVal = formatMoneyInput(e.target.value);
    customAmountField.onChange(formattedVal);
    amountField.input.onChange(null);
    dispatch(productActions.setProductDonation(formattedVal));
  };

  if (noSuggestions) {
    return (
      <div className={classes.bigInputWrapper}>
        <TextField
          label="Enter amount"
          className={classes.bigInput}
          value={customAmount || ""}
          onChange={onChange}
          InputProps={{
            autoComplete: "off",
            startAdornment: customAmount ? (
              <InputAdornment position="start">$</InputAdornment>
            ) : null,
          }}
          type="number"
        />
      </div>
    );
  }

  return (
    <div className={classes.card}>
      <div className={classes.suggestionAmount}>Other</div>
      <div className={classes.suggestionText}>Enter custom amount</div>
      <TextField
        size="small"
        className={classes.input}
        value={customAmount || ""}
        onChange={onChange}
        InputProps={{
          autoComplete: "off",
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        type="number"
      />
    </div>
  );
}

const styles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "#F5F5F5",
    paddingBottom: 24,
  },
  header: {
    textAlign: "center",
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    paddingTop: 56,
    paddingBottom: 48,
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      letterSpacing: 0.3,
      paddingTop: 40,
      paddingBottom: 32,
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginLeft: -12,
    marginRight: -12,
    width: "calc(100% + 24px)",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
      padding: "0 8px",
    },
  },
  card: {
    width: 170,
    minHeight: 165,
    padding: "24px 11px",
    borderRadius: 8,
    marginLeft: 12,
    marginRight: 12,
    marginBottom: 24,
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: ({ isSelected }) =>
      isSelected ? `2px solid ${theme.palette.primary.main}` : "none",
    [theme.breakpoints.down("md")]: {
      width: 155,
      minHeight: 155,
      marginLeft: 8,
      marginRight: 8,
      padding: "22px 11px",
      marginBottom: 16,
    },
  },
  suggestionAmount: {
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    color: theme.palette.secondary.main,
  },
  suggestionText: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "16px",
    textAlign: "center",
    marginTop: 14,
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
      lineHeight: "14px",
    },
  },
  button: {
    marginTop: 18,
    height: 40,
  },
  input: {
    width: 100,
    marginTop: 18,
    [`& fieldset`]: {
      borderRadius: 19,
    },
  },
  bigInputWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: 12,
  },
  bigInput: {
    width: 280,
  },
}));
