import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { makeStyles, Dialog, CircularProgress } from "@material-ui/core";
import { useForm } from "react-final-form";
import { gatewayProviderIds } from "../../lib";
import { setToast } from "../../state";
import axios from "axios";

function _CardknoxApplePay({
  submitting,
  disableRetry,
  setDisplayingApplePay,
  needsPayment,
  orderTotal,
  orderHasDonationOrDonationItems,
  gatewayProviderId,
  allow_apple_pay,
  apple_pay_merchant_id,
}) {
  const [isCKApplePayCampaign, setIsCKApplePayCampaign] = useState(false);
  const [buttonId, setButtonId] = useState("");
  const [canUseAP, setCanUseAP] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [applePaySubmit, setApplePaySubmit] = useState(false);
  const classes = styles({ showButton });
  const form = useForm();
  const dispatch = useDispatch();
  const getOrderTotal = () => orderTotal;

  // IMPORTANT: the applePay button ID needs to be unique every time this component renders or it will cause errors & weird behavior
  useEffect(() => {
    setButtonId(`ckApplePayButton${Math.random()}`);
  }, []);

  useEffect(() => {
    if (
      buttonId &&
      gatewayProviderId === gatewayProviderIds.CARDKNOX &&
      allow_apple_pay &&
      apple_pay_merchant_id
    ) {
      setIsCKApplePayCampaign(true);
    } else setIsCKApplePayCampaign(false);
  }, [gatewayProviderId, allow_apple_pay, apple_pay_merchant_id, buttonId]);

  useEffect(() => {
    if (!isCKApplePayCampaign || window.CKApplePayConfig) return;
    window.CKApplePayConfig = {
      init: () => {
        return {
          buttonOptions: {
            buttonContainer: buttonId,
            buttonColor: "black",
            buttonType: "check-out",
          },
          merchantIdentifier: apple_pay_merchant_id,
          onGetTransactionInfo: "CKApplePayConfig.onGetTransactionInfo",
          onPaymentMethodSelected: "CKApplePayConfig.onPaymentMethodSelected",
          onValidateMerchant: "CKApplePayConfig.onValidateMerchant",
          onPaymentAuthorize: "CKApplePayConfig.onPaymentAuthorize",
          onPaymentComplete: "CKApplePayConfig.onPaymentComplete",
          onAPButtonLoaded: "CKApplePayConfig.onAPButtonLoaded",
          isDebug: false,
        };
      },
      onGetTransactionInfo: () => {
        return {
          lineItems: [],
          total: { label: "Total", amount: getOrderTotal() },
        };
      },
      onPaymentMethodSelected: () => {
        return Promise.resolve({
          lineItems: [],
          total: { label: "Total", amount: getOrderTotal() },
        });
      },
      onValidateMerchant: async () => {
        try {
          const { data } = await axios.post(
            "https://api.cardknox.com/applepay/validate",
          );
          return Promise.resolve(JSON.stringify(data));
        } catch (error) {
          dispatch(
            setToast(
              "We are having trouble connecting to the Merchant, please try again",
            ),
          );
          return Promise.reject();
        }
      },
      onPaymentAuthorize: applePayload => {
        onSubmit(applePayload.token.paymentData);
        return Promise.resolve();
      },
      onPaymentComplete: () => null,
      onAPButtonLoaded: resp => {
        if (resp.status === 100) setCanUseAP(true);
      },
    };

    return () => {
      window.CKApplePayConfig = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCKApplePayCampaign]);

  useEffect(() => {
    if (!isCKApplePayCampaign) return;
    const { ApplePaySession, ckApplePay, CKApplePayConfig } = window;
    if (
      ApplePaySession &&
      ApplePaySession.canMakePayments() &&
      ckApplePay &&
      CKApplePayConfig
    ) {
      ckApplePay.enableApplePay({
        initFunction: "CKApplePayConfig.init",
        // amountField: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCKApplePayCampaign]);

  useEffect(() => {
    if (!isCKApplePayCampaign) return;
    const show = Boolean(
      canUseAP &&
        needsPayment &&
        !orderHasDonationOrDonationItems &&
        !disableRetry,
    );
    setShowButton(show);
    setDisplayingApplePay(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCKApplePayCampaign,
    needsPayment,
    orderHasDonationOrDonationItems,
    canUseAP,
    disableRetry,
  ]);

  useEffect(() => {
    if (!isCKApplePayCampaign) return;
    if (window.ckApplePay) window.ckApplePay.updateAmount();
  }, [orderTotal, isCKApplePayCampaign]);

  useEffect(() => {
    if (applePaySubmit) setShowDialog(true);
  }, [applePaySubmit]);

  useEffect(() => {
    if (!submitting) setShowDialog(false);
  }, [submitting]);

  const onSubmit = async applePayData => {
    setApplePaySubmit(true);
    form.change("applePayData", applePayData);
    form.submit();
  };

  if (!isCKApplePayCampaign) return <></>;
  return (
    <Fragment>
      <div className={classes.apContainer}>
        <div className={classes.or}>Or</div>
        <div className={classes.apButton} id={buttonId}></div>
      </div>
      <Dialog open={showDialog} maxWidth={false}>
        <div className={classes.submittingDialog}>
          <div className={classes.submitting}>
            <div>Submitting...</div>
            <CircularProgress
              className={classes.spinner}
              color="secondary"
              size={50}
            />
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
}

export const CardknoxApplePay = connect(state => {
  const {
    campaign: { gatewayProviderId, allow_apple_pay, apple_pay_merchant_id },
    product: { orderTotal, orderHasDonationOrDonationItems },
  } = state;
  return {
    gatewayProviderId,
    allow_apple_pay,
    apple_pay_merchant_id,
    orderTotal,
    orderHasDonationOrDonationItems,
  };
})(_CardknoxApplePay);

const styles = makeStyles(_theme => ({
  apContainer: {
    marginTop: ({ showButton }) => (showButton ? 16 : 0),
    visibility: ({ showButton }) =>
      showButton ? "visible" : "hidden !important",
    height: ({ showButton }) => (showButton ? "fit-content" : 0),
  },
  or: {
    fontSize: 16,
    letterSpacing: 0.2,
    lineHeight: "18px",
    textAlign: "center",
    marginBottom: 16,
  },
  apButton: {
    // don't remove these styles
    visibility: ({ showButton }) =>
      showButton ? "visible" : "hidden !important",
    height: ({ showButton }) => (showButton ? 40 : 0),
  },
  submittingDialog: {
    backgroundColor: "#FFFFFF",
    width: 400,
    maxWidth: "100%",
    height: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  submitting: {
    fontSize: 22,
    fontWeight: "bold",
    width: 50,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  spinner: {
    position: "absolute",
  },
}));
