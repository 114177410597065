import React, { Fragment, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { donationActions, productActions } from "../../state";
import { productTypes } from "../../lib";

function _OrderChangeListener(props) {
  const {
    selectedProducts,
    online_fee_flat,
    online_fee_percentage,
    custom_fee,
    productDonation,
    productDonationProcessingFee,
    tax,
    shipping,
    giftCardsValue,
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    let orderOnlineFee = 0;
    let orderCustomFee = 0;
    let orderItemsTotal = 0;
    let orderItemCount = 0;
    let hasDonationItems = false;
    const donation = Number(productDonation ? productDonation : 0);
    Object.keys(selectedProducts).forEach(id => {
      const {
        qty,
        product: { product_price, product_type },
      } = selectedProducts[id];
      const productTotal = Number(qty * ((Number(product_price) * 10) / 10));
      orderItemsTotal = Number((orderItemsTotal + productTotal).toFixed(2));
      orderItemCount += qty;
      if (product_type === productTypes.DONATION) hasDonationItems = true;
    });

    // ONLINE FEE
    if (orderItemCount) {
      if (online_fee_flat && online_fee_flat > 0)
        orderOnlineFee = Number(online_fee_flat);
      else if (online_fee_percentage) {
        orderOnlineFee = Number(
          (
            ((orderItemsTotal + Number(shipping)) / 100) *
            Number(online_fee_percentage)
          ).toFixed(2),
        );
      }
      // IMPORTANT!
      // when there are items in the order the `productDonationProcessingFee` is added to the online fee so the customer isn't shown so many different fees
      orderOnlineFee = Number(
        (orderOnlineFee + Number(productDonationProcessingFee || 0)).toFixed(2),
      );
    }

    // CUSTOM FEE
    if (orderItemCount && custom_fee > 0) {
      orderCustomFee = Number(custom_fee);
    }

    // CHARGE TOTAL
    let chargeTotal = 0;
    if (orderItemCount) {
      chargeTotal =
        orderItemsTotal +
        Number(shipping) +
        orderOnlineFee +
        orderCustomFee +
        donation +
        Number(tax);
    } else {
      chargeTotal = donation + Number(productDonationProcessingFee || 0);
    }

    // SUBTRACT GIFT CARDS
    const giftCards = Number(giftCardsValue);
    let orderTotal = chargeTotal - giftCards;
    // if giftCardsValue is more then all charges; set total to 0
    if (orderTotal < 0) orderTotal = 0;

    const orderHasDonationOrDonationItems = Boolean(
      hasDonationItems || donation,
    );

    dispatch(
      productActions.updateOrderFigures({
        orderItemsTotal,
        orderOnlineFee,
        orderCustomFee,
        orderTotal,
        orderItemCount,
        emptyCart: Boolean(!donation && !orderItemCount),
        orderHasDonationOrDonationItems,
      }),
    );
    dispatch(donationActions.setGrandTotal(chargeTotal.toFixed(2)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedProducts,
    online_fee_flat,
    online_fee_percentage,
    custom_fee,
    productDonation,
    productDonationProcessingFee,
    tax,
    shipping,
    giftCardsValue,
  ]);

  return <Fragment />;
}

export const OrderChangeListener = connect(state => {
  const {
    selectedProducts,
    online_fee_flat,
    online_fee_percentage,
    custom_fee,
    productDonation,
    productDonationProcessingFee,
    tax = 0,
    shipping = 0,
    giftCardsValue = 0,
  } = state.product;
  return {
    selectedProducts,
    online_fee_flat,
    online_fee_percentage,
    custom_fee,
    productDonation,
    productDonationProcessingFee,
    tax,
    shipping,
    giftCardsValue,
  };
})(_OrderChangeListener);
