import { makeStyles } from "@material-ui/core";

export const socialMediaStyles = makeStyles(theme => ({
  container: {
    width: "56px",
    position: "fixed",
    right: 0,
    top: "50vh",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px 0 0 8px",

    boxShadow: "0 8px 14px 0 rgba(224,229,238,0.5)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px 0",
    zIndex: 9999,
  },
  button: {
    margin: "0 5px",
  },
  largeButton: {
    margin: "0 2px",
    [theme.breakpoints.down("xs")]: {
      margin: "0 1.5px",
    },
  },
  icon: {
    width: "24px",
    height: "24px",
  },
  largeIcon: {
    width: "32px",
    height: "32px",
  },
}));
