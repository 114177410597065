import { volunteerRegistrationModes } from "../../lib";
import { volunteerTypes } from "./actions";
const initialState = {
  volunteerRegistrationMode: volunteerRegistrationModes.REGISTER,
  volunteers: [],
  volunteerCount: 0,
  volunteerUserEmail: "",
  volunteerUserSlug: "",
  volunteerAccounts: [],
};

const volunteerReducers = (state = initialState, action) => {
  switch (action.type) {
    case volunteerTypes.VOLUNTEER_CREATION_SUCCESS:
      return {
        ...state,
        volunteerRegistrationMode: volunteerRegistrationModes.SUCCESS,
      };
    case volunteerTypes.VOLUNTEER_REGISTRATION_FORM_RESET:
      return {
        ...state,
        volunteerRegistrationMode: volunteerRegistrationModes.REGISTER,
        volunteerUserEmail: "",
        volunteerUserSlug: "",
        volunteerAccounts: [],
      };
    case volunteerTypes.VOLUNTEERS_SUCCESS:
      const currList = action.refresh ? [] : state.volunteers;
      return {
        ...state,
        volunteerCount: action.count,
        volunteers: [...currList, ...action.volunteers],
      };
    case volunteerTypes.VOLUNTEERS_CLEAR:
      return { ...state, volunteers: [], volunteerCount: 0 };
    case volunteerTypes.VOLUNTEER_REGISTRATION_MODE_CHANGE:
      return { ...state, volunteerRegistrationMode: action.mode };
    case volunteerTypes.VOLUNTEER_USER_INFO_SET:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export { volunteerReducers };
