import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DecrementIcon from "@material-ui/icons/Remove";
import RemoveIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { productActions } from "../../state";
import { ProductDetails } from "./ProductDetails";

function _CartProductCard(props) {
  const {
    productInfo: {
      qty,
      product,
      product: {
        id,
        product_name,
        product_price,
        thumbnail_image,
        can_personalize,
      } = {},
      personalization,
    } = {},
    actions: { removeProduct, changeQuantity },
    forConfirmation,
  } = props;
  const classes = styles();
  const [showDetails, setShowDetails] = useState(false);

  const handleRemoveProduct = () => removeProduct(id);

  const handleQtyChange = (id, action) => {
    changeQuantity(id, action);
    if (can_personalize && action === "increment") setShowDetails(true);
  };

  return (
    <Fragment>
      <div className={classes.card}>
        <div className={classes.imageAndMainContent}>
          <img className={classes.image} src={thumbnail_image} alt="product" />
          <div className={classes.mainContent}>
            <div className={classes.productName}>{product_name}</div>
            <div className={classes.priceAndQty}>
              <div className={classes.price}>{`$${product_price}`}</div>
              {!forConfirmation && (
                <div className={classes.qtyActions}>
                  <IconButton
                    onClick={
                      qty > 1
                        ? () => handleQtyChange(id, "decrement")
                        : handleRemoveProduct
                    }
                  >
                    {qty > 1 && <DecrementIcon className={classes.icon} />}
                    {qty < 2 && (
                      <RemoveIcon className={classes.icon} fontSize="small" />
                    )}
                  </IconButton>
                  <div className={classes.qty}>{qty}</div>
                  <IconButton onClick={() => handleQtyChange(id, "increment")}>
                    <AddIcon className={classes.icon} />
                  </IconButton>
                </div>
              )}
              {forConfirmation && <div className={classes.confQty}>{qty}</div>}
            </div>
          </div>
        </div>
        {can_personalize && Array.isArray(personalization) && (
          <div className={classes.personalizationContainer}>
            {personalization.map((txt, index) => (
              <div key={index} className={classes.personalizationRow}>
                <div>
                  <span className={classes.personalization}>
                    Personalization:
                  </span>{" "}
                  {txt}
                </div>
                <IconButton onClick={() => setShowDetails(true)}>
                  <EditIcon className={classes.icon} fontSize="small" />
                </IconButton>
              </div>
            ))}
          </div>
        )}
      </div>

      <ProductDetails
        product={product}
        handleClose={setShowDetails}
        open={showDetails}
      />
    </Fragment>
  );
}

export const CartProductCard = connect(undefined, dispatch => {
  return { actions: bindActionCreators(productActions, dispatch) };
})(_CartProductCard);

export const styles = makeStyles(theme => ({
  card: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
    paddingBottom: 32,
    marginBottom: 32,
  },
  imageAndMainContent: {
    display: "flex",
    minHeight: 75,
  },
  image: {
    width: 75,
    height: 75,
    marginRight: 24,
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
  },
  productName: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
    lineHeight: "17px",
  },
  priceAndQty: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 4,
    width: "100%",
    alignItems: "flex-end",
  },
  price: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.25,
    color: theme.palette.primary.main,
  },
  qtyActions: {
    width: 114,
    height: 38,
    borderRadius: 19,
    border: `1px solid ${theme.palette.text.secondary}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 6px",
  },
  qty: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0,
  },
  icon: {
    color: theme.palette.text.primary,
  },
  confQty: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
  },
  personalizationContainer: {
    marginTop: 16,
  },
  personalizationRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 8,
    fontSize: 16,
    letterSpacing: 0.2,
    lineHeight: "17px",
  },
  personalization: {
    fontWeight: 600,
  },
}));
