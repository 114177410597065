import { makeStyles } from "@material-ui/core";

export const volunteerRegistrationFormStyles = makeStyles(theme => ({
  instructions: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.2,
    lineHeight: "18px",
    textAlign: "center",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "20px",
      marginBottom: 8,
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: 700,
    maxWidth: "100%",
  },
  inputGroup: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  input: { marginTop: "24px" },
  halfWidthInput: {
    marginTop: "24px",
    width: "calc(50% - 12px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  checkboxLabel: {
    fontSize: 16,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  capitalize: {
    textTransform: "capitalize",
  },
  extIdMsgWrapper: {
    textAlign: "center",
    paddingTop: 8,
    fontSize: "13px",
    lineHeight: "17px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
      lineHeight: "15px",
    },
  },
  emailLink: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
  },
  submit: {
    minHeight: 56,
    width: "100%",
    marginTop: 48,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    borderRadius: 28,
    lineHeight: "22px",
    paddingTop: 8,
    paddingBottom: 8,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "18px",
    },
  },
  additionalUsersMsg: {
    marginTop: 16,
    fontSize: 16,
    lineHeight: "19px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  strong: {
    fontWeight: 500,
  },
}));
