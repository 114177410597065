import React, { useState } from "react";
import { useMediaQuery, Container, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Facebook,
  Twitter,
  Linkedin,
  HashLinkRef,
  Whatsapp,
  IMessage,
  Email,
  PrivacyModal,
} from "../components";
import { footerStyles } from "./Footer.styles";
import { bindActionCreators } from "redux";
import {
  donationActions,
  getUrls,
  productActions,
  submissionActions,
  volunteerActions,
} from "../state";
import { isBigKahunaCompany } from "../lib";

function _Footer(props) {
  const {
    companyId,
    company_name,
    company_logo,
    company_website,
    loginUrl,
    homeUrl,
    registerUrl,
    actions: {
      resetDonation,
      resetVolunteerRegistrationForm,
      resetCart,
      openSubmissionDialog,
    },
  } = props;
  const classes = footerStyles();
  const [showPrivacy, setShowPrivacy] = useState(false);
  const smallDevice = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const isBigKahuna = isBigKahunaCompany(companyId);

  const Privacy = () => (
    <div className={classes.privacy} onClick={() => setShowPrivacy(true)}>
      Privacy
    </div>
  );

  const ContactCompany = () => (
    <div
      className={classes.contactCompany}
      onClick={() => openSubmissionDialog("company")}
    >
      Contact us
    </div>
  );

  const PoweredBy = () => (
    <div>&copy; {`Powered by ${company_name || ""}`}</div>
  );

  const restPage = () => {
    resetDonation();
    resetCart();
    resetVolunteerRegistrationForm();
  };

  return (
    <div className={classes.container}>
      {smallDevice && (
        <div className={classes.mobileContent}>
          <div className={classes.socialLinks}>
            <Facebook /> <Twitter /> <Linkedin /> <Whatsapp />
            <Email /> <IMessage />
          </div>

          <div className={classes.mobileButtons}>
            <Button
              size="small"
              className={classes.mobileButton}
              component={HashLinkRef}
              to={`${homeUrl}#top`}
              onClick={restPage}
            >
              Home
            </Button>
            <Button
              size="small"
              className={classes.mobileButton}
              component={Link}
              to={registerUrl}
              onClick={restPage}
            >
              Register
            </Button>
            {!isBigKahuna && (
              <Button
                size="small"
                className={classes.mobileButton}
                onClick={() => openSubmissionDialog("org")}
              >
                Contact
              </Button>
            )}
            <Button
              size="small"
              className={classes.mobileButton}
              href={loginUrl}
              onClick={restPage}
            >
              Login
            </Button>
            <Button
              size="small"
              className={classes.mobileButton}
              onClick={() => setShowPrivacy(true)}
            >
              Privacy
            </Button>
          </div>

          {company_logo && (
            <a href={company_website} target="_blank" rel="noopener noreferrer">
              <img
                className={classes.logo}
                src={company_logo}
                alt="powered by"
              />
            </a>
          )}
        </div>
      )}

      <div className={classes.bottomBar}>
        <Container>
          <div className={classes.bottomBarContent}>
            {!smallDevice && company_logo && (
              <a
                href={company_website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.logo}
                  src={company_logo}
                  alt="powered by"
                />
              </a>
            )}
            {!smallDevice && (
              <div className={classes.bottomLinks}>
                <ContactCompany />
                <Privacy />
                <PoweredBy />
              </div>
            )}
            {smallDevice && (
              <div className={classes.bottomLinks}>
                <PoweredBy />
                <ContactCompany />
              </div>
            )}
          </div>
        </Container>
      </div>
      <PrivacyModal isOpen={showPrivacy} toggleModal={setShowPrivacy} />
    </div>
  );
}

export const Footer = connect(
  state => {
    const {
      companyId,
      company_name,
      company_logo,
      company_website,
    } = state.campaign;
    const { loginUrl, homeUrl, registerUrl } = getUrls(state);
    return {
      companyId,
      company_name,
      company_logo,
      company_website,
      loginUrl,
      homeUrl,
      registerUrl,
    };
  },
  dispatch => {
    return {
      actions: {
        ...bindActionCreators(volunteerActions, dispatch),
        ...bindActionCreators(donationActions, dispatch),
        ...bindActionCreators(productActions, dispatch),
        ...bindActionCreators(submissionActions, dispatch),
      },
    };
  },
)(_Footer);
