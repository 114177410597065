const RAFFLE_CAMPAIGN = 1;
const MATCHING_CAMPAIGN = 2;
const DONATION_CAMPAIGN = 3;
const PRODUCT_CAMPAIGN = 4;
const CC_PAYMENT = "cc";
const AUTHORIZE_DON_NET_EL_ID = "authorize-net-container";
const RETRY_ABLE_DONATION_CODE = 103;
const RECAPTCHA_FAILURE_CODE = 116;
const RECAPTCHA_FAILURE_CAN_RETRY_CODE = 117;
const INVALID_ACTION_CODE = 119;
const APP_ASSET_BASE_URL = "https://jm-assets.nyc3.digitaloceanspaces.com/app";
const PRODUCTS_EL_ID = "products";
const DONATE_EL_ID = "donate";

function campaignTypeBooleans(campaignTypeId) {
  const isRaffle = campaignTypeId === RAFFLE_CAMPAIGN;
  const isMatching = campaignTypeId === MATCHING_CAMPAIGN;
  const isDonation = campaignTypeId === DONATION_CAMPAIGN;
  const isProduct = campaignTypeId === PRODUCT_CAMPAIGN;
  return { isRaffle, isDonation, isMatching, isProduct };
}

const campaignTypeIds = {
  RAFFLE: 1,
  MATCH: 2,
  DONATION: 3,
  PRODUCT: 4,
};

const ccEntryModes = {
  MANUAL: "Manual",
  SWIPE: "Swipe",
};

function hexToRGBA(hex = "#FFF", opacity = 1) {
  let rgba = "";
  try {
    rgba =
      "rgba(" +
      (hex = hex.replace("#", ""))
        .match(new RegExp("(.{" + hex.length / 3 + "})", "g"))
        .map(function(l) {
          return parseInt(hex.length % 2 ? l + l : l, 16);
        })
        .concat(opacity)
        .join(",") +
      ")";
  } catch (error) {
    rgba = "rgba(255,255,255,0.5)";
  }
  return rgba;
}

const PO_BOX_REGEX = /\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/i;

const productTypes = {
  PHYSICAL: "physical",
  DIGITAL: "digital",
  PRIZE: "prize",
  DONATION: "donation",
};

function formatMoney(num = 0, addMinus = false) {
  const padded = Number(num)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${addMinus ? "-" : ""}$${padded}`;
}

function formatMoneyUnPadded(amount) {
  if (!amount) return "0";
  const num = Number(amount);
  const paddedNum = !Number.isInteger(num) ? num.toFixed(2) : num;
  const formattedNum = paddedNum
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `$${formattedNum}`;
}

const gatewayProviderIds = {
  AUTHORIZE_DOT_NET: 1,
  STRIPE: 2,
  USA_EPAY: 3,
  CARDKNOX: 4,
  BANQUEST: 5,
};

function isProductCampaign(campaignTypeId) {
  return campaignTypeId === PRODUCT_CAMPAIGN;
}

function isMatchingCampaign(campaignTypeId) {
  return campaignTypeId === MATCHING_CAMPAIGN;
}

function isRaffleCampaign(campaignTypeId) {
  return campaignTypeId === RAFFLE_CAMPAIGN;
}

const isPerfectGameOrg = orgId => {
  const PGOrgId = process.env.REACT_APP_PERFECT_GAME_ORG_ID;
  if (!orgId || !PGOrgId) return false;
  return Number(orgId) === Number(PGOrgId);
};

const isBigKahunaCompany = companyId => {
  const BKCompanyId = process.env.REACT_APP_BIG_KAHUNA_COMPANY_ID;
  if (!companyId || !BKCompanyId) return false;
  return Number(companyId) === Number(BKCompanyId);
};

const isGivverCompany = companyId => {
  const givverCompanyId = process.env.REACT_APP_GIVVR_COMPANY_ID;
  if (!companyId || !givverCompanyId) return false;
  return Number(companyId) === Number(givverCompanyId);
};

const PROCESSOR_ERROR_MSG =
  "We are having trouble connecting to the processor, please try again later.";

const hebrewDates = {
  "1": "א",
  "2": "ב",
  "3": "ג",
  "4": "ד",
  "5": "ה",
  "6": "ו",
  "7": "ז",
  "8": "ח",
  "9": "ט",
  "10": "י",
  "11": "יא",
  "12": "יב",
  "13": "יג",
  "14": "יד",
  "15": "טו",
  "16": "טז",
  "17": "יז",
  "18": "יח",
  "19": "יט",
  "20": "כ",
  "21": "כא",
  "22": "כב",
  "23": "כג",
  "24": "כד",
  "25": "כה",
  "26": "כו",
  "27": "כז",
  "28": "כח",
  "29": "כט",
  "30": "ל",
};

const calendarTypes = {
  ENGLISH: "English",
  HEBREW: "Hebrew",
};

function calculatePercentOfNum(num, percent) {
  if (!percent || !num) return "0";
  const amount = (Number(num) * (Number(percent) / 100)).toFixed(2);
  return amount;
}

const paymentPlans = {
  RECURRING: "recurring",
  RECURRING_DEFAULTED: "recurring-defaulted",
  INSTALLMENTS: "installments",
  INSTALLMENTS_DEFAULTED: "installments-defaulted",
};

function getUserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const productStructureTypes = {
  STANDARD: "standard",
  CONFIGURABLE: "configurable",
  VARIANT: "variant",
};

const configurableProductOptionDisplayTypes = {
  DROPDOWN: "dropdown",
  TEXT_SWATCH: "text-swatch",
  COLOR_SWATCH: "color-swatch",
};

const shippingCalculatedByOptions = {
  RATE_TABLE: "rate-table",
  LIVE_RATES: "live-rates",
};

const volunteerRegistrationModes = {
  REGISTER: "REGISTER",
  SELECT_USER: "SELECT_USER",
  ADD_TO_CAMPAIGN: "ADD_TO_CAMPAIGN",
  LOGIN: "LOGIN",
  SUCCESS: "SUCCESS",
};

const AFFILIATES = {
  VOLUNTEER_WELCOME_TEXT: 1,
  VOLUNTEER_REMINDER_TEXT: 2,
  VOLUNTEER_TO_CONTACT_TEXT: 3,
  VOLUNTEER_APPROACHING_PRIZE_TIER_TEXT: 4,
  EZ_SHARING_VIA_VOLUNTEER_REMINDER_TEXT: 6,
  EZ_SHARING_VIA_VOLUNTEER_APPROACHING_PRIZE_TIER_TEXT: 7,
  CONTACT_EMAIL_1: 10,
  CONTACT_EMAIL_2: 11,
  CONTACT_EMAIL_3: 12,
  CONTACT_EMAIL_4: 13,
  CONTACT_EMAIL_5_AND_UP: 14,
  VOLUNTEER_WELCOME_EMAIL: 15,
  MANUAL_CONTACT_EMAIL: 16,
  DASHBOARD_SHARING_LINKS: 17,
  VOLUNTEER_APPROACHING_PRIZE_TIER_EMAIL: 18,
  EZ_SHARING_VIA_VOLUNTEER_APPROACHING_PRIZE_TIER_EMAIL: 19,
  VOLUNTEER_SETUP_SHARING_LINKS: 20,
  KICKOFF_BASED_CHECKPOINT_EMAIL: 21,
  CAMPAIGN_END_BASED_CHECKPOINT_EMAIL: 22,
  WEBSITE_SHARING_LINKS: 23,
};

export {
  campaignTypeIds,
  campaignTypeBooleans,
  ccEntryModes,
  hexToRGBA,
  PO_BOX_REGEX,
  productTypes,
  formatMoney,
  formatMoneyUnPadded,
  gatewayProviderIds,
  isProductCampaign,
  isMatchingCampaign,
  isRaffleCampaign,
  CC_PAYMENT,
  AUTHORIZE_DON_NET_EL_ID,
  RETRY_ABLE_DONATION_CODE,
  RECAPTCHA_FAILURE_CODE,
  RECAPTCHA_FAILURE_CAN_RETRY_CODE,
  INVALID_ACTION_CODE,
  isPerfectGameOrg,
  PROCESSOR_ERROR_MSG,
  isBigKahunaCompany,
  isGivverCompany,
  hebrewDates,
  calendarTypes,
  calculatePercentOfNum,
  paymentPlans,
  getUserTimezone,
  APP_ASSET_BASE_URL,
  productStructureTypes,
  configurableProductOptionDisplayTypes,
  shippingCalculatedByOptions,
  DONATE_EL_ID,
  PRODUCTS_EL_ID,
  volunteerRegistrationModes,
  AFFILIATES,
};
