import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  RulesAndDisclaimers,
  CampaignStats,
  DonationForm,
  DonationConfirmation,
  SocialMediaBar,
  PictureAndParagraph,
  MainBanner,
  ParticipantContainer,
  Sponsors,
  UnderBannerHeader,
  Showcase,
  VolunteerPagesOnly,
} from "../components";

function _OrgHome(props) {
  const {
    donationSuccess,
    campaign: { orgId, picture, text_html, video_link, volunteer_pages_only },
  } = props;

  if (!orgId) return <></>;
  if (volunteer_pages_only) return <VolunteerPagesOnly />;
  if (donationSuccess) return <DonationConfirmation />;
  return (
    <Fragment>
      <MainBanner />
      <UnderBannerHeader />
      <CampaignStats />
      <Showcase />
      <Sponsors />
      <PictureAndParagraph
        picture={picture}
        video_link={video_link}
        text_html={text_html}
      />
      <ParticipantContainer isHomePage={true} />
      <DonationForm />
      <RulesAndDisclaimers />
      <SocialMediaBar />
    </Fragment>
  );
}

export const OrgHome = connect(state => {
  const {
    campaign,
    donation: { donationSuccess },
  } = state;
  return { campaign, donationSuccess };
})(_OrgHome);
