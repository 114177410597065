import { makeStyles } from "@material-ui/core";

export const footerStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    // marginTop: "auto",
    maxWidth: "100%",
    // paddingTop: "40px",
  },
  mobileContent: {
    backgroundColor: "#F5F5F5",
    padding: "32px 16px 24px 16px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    maxWidth: "100%",
  },
  socialLinks: {
    paddingBottom: 24,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  mobileButtons: {
    marginBottom: 24,
    textAlign: "center",
  },
  mobileButton: {
    fontSize: 14,
    letterSpacing: 0.17,
    width: "fit-content",
    minWidth: "fit-content",
    margin: "0 3px",
  },
  bottomBar: {
    height: 96,
    width: "100%",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    [theme.breakpoints.only("md")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      height: 48,
    },
  },
  bottomBarContent: {
    height: 96,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "0.17px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      padding: "0 16px",
      letterSpacing: 0.15,
      height: 48,
    },
  },
  contactCompany: {
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 24,
    },
  },
  privacy: {
    cursor: "pointer",
    margin: "0 40px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "12px",
    },
  },
  logo: {
    maxHeight: 48,
    maxWidth: 200,
  },
  bottomLinks: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "center",
    },
  },
}));
