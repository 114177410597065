import { APIClient } from "../../lib";
import { uiTypes } from "..";

const campaignTypes = {
  CAMPAIGN_FETCH_SUCCESS: "CAMPAIGN_FETCH_SUCCESS",
  CAMPAIGN_FETCH_NOT_FOUND: "CAMPAIGN_FETCH_NOT_FOUND",
  CAMPAIGN_CLEAR: "CAMPAIGN_CLEAR",
  CAMPAIGN_PROGRESS_FETCH_SUCCESS: "CAMPAIGN_PROGRESS_FETCH_SUCCESS",
  CAMPAIGN_DONATION_GROUP_FETCH_SUCCESS:
    "CAMPAIGN_DONATION_GROUP_FETCH_SUCCESS",
  CAMPAIGN_DATA_FETCH_SUCCESS: "CAMPAIGN_DATA_FETCH_SUCCESS",
};

const campaignActions = {
  getWebpageInfo(
    orgSlug,
    campaignSlug,
    volunteerSlug,
    teamSlug,
    volunteerCampaignId,
  ) {
    return async (dispatch, _getState) => {
      let url;
      switch (true) {
        case Boolean(volunteerCampaignId):
          url = `/volunteer_campaigns/${volunteerCampaignId}/webpage`;
          break;
        case !volunteerSlug && !teamSlug:
          url = `/orgs/webpage/${orgSlug}/${campaignSlug}`;
          break;
        case Boolean(volunteerSlug):
          url = `/volunteers/webpage/${orgSlug}/${campaignSlug}/${volunteerSlug}`;
          break;
        case Boolean(teamSlug):
          url = `/teams/webpage/${orgSlug}/${campaignSlug}/${teamSlug}`;
          break;
        default:
      }

      const response = await APIClient.get(url);
      dispatch({ type: campaignTypes.CAMPAIGN_CLEAR });
      const { error, errorMessage, status, data } = response;
      if (error) {
        if (status === 404) {
          return dispatch({ type: campaignTypes.CAMPAIGN_FETCH_NOT_FOUND });
        }
        return dispatch({ type: uiTypes.UI_DATA_FETCH_ERROR, errorMessage });
      }
      dispatch({ type: campaignTypes.CAMPAIGN_FETCH_SUCCESS, data });
    };
  },
  getProgressStats(
    campaign_id,
    campaign_type_id,
    profit_based,
    volunteer_id,
    team_id,
    volunteer_item_based,
  ) {
    return async (dispatch, _getState) => {
      let url = `/campaigns/${campaign_id}/progress?campaign_type_id=${campaign_type_id}&profit_based=${profit_based}`;
      if (volunteer_id) url = `${url}&volunteer_id=${volunteer_id}`;
      if (team_id) url = `${url}&team_id=${team_id}`;
      if (volunteer_id && volunteer_item_based) {
        url = `${url}&volunteer_item_based=true`;
      }

      const response = await APIClient.get(url);
      const { error, errorMessage, data } = response;
      if (error) {
        return dispatch({ type: uiTypes.UI_DATA_FETCH_ERROR, errorMessage });
      }
      dispatch({ type: campaignTypes.CAMPAIGN_PROGRESS_FETCH_SUCCESS, data });
    };
  },
  getCampaignDonationGroupDisplayData(
    campaign_donation_group_id,
    volunteer_id,
  ) {
    return async (dispatch, _getState) => {
      let url = `/campaign_donation_groups/${campaign_donation_group_id}/display_data`;
      if (volunteer_id) url = `${url}?volunteer_id=${volunteer_id}`;
      const response = await APIClient.get(url);
      const { error, errorMessage, data } = response;
      if (error) {
        return dispatch({ type: uiTypes.UI_DATA_FETCH_ERROR, errorMessage });
      }
      dispatch({
        type: campaignTypes.CAMPAIGN_DONATION_GROUP_FETCH_SUCCESS,
        data,
      });
    };
  },
  getShowcaseDisplayData(campaign_id) {
    return async (dispatch, _getState) => {
      const response = await APIClient.get(
        `/campaign_showcase/campaigns/${campaign_id}/display_data`,
      );
      const { error, errorMessage, data } = response;
      if (error) {
        return dispatch({ type: uiTypes.UI_DATA_FETCH_ERROR, errorMessage });
      }
      dispatch({
        type: campaignTypes.CAMPAIGN_DATA_FETCH_SUCCESS,
        data,
      });
    };
  },
};

export { campaignTypes, campaignActions };
