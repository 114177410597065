import React from "react";
import { IconButton } from "@material-ui/core";
import ImessageIcon from "../../images/IMessage.svg";
import { connect } from "react-redux";
import { getSocialMediaTxtSelector } from "../../state";
import { socialMediaStyles } from "./SocialMedia.styles";
import {
  isIOS,
  deviceDetect,
  mobileVendor,
  mobileModel,
  OsTypes,
} from "react-device-detect";

export function _IMessage({ large, socialTxt }) {
  const classes = socialMediaStyles();
  console.log({
    MV: mobileVendor,
    mm: mobileModel,
    OsTypes,
    x: navigator,
    VV: navigator.vendor,
  });
  return (
    <IconButton
      href={`sms://8482996323?body=hi there`}
      // href={isIOS ? `sms://&body=${socialTxt}` : `sms:?&body=${socialTxt}`}
      className={large ? classes.largeButton : classes.button}
    >
      <img
        src={ImessageIcon}
        alt="iMessage"
        className={large ? classes.largeIcon : classes.icon}
      ></img>
    </IconButton>
  );
}

export const IMessage = connect(state => {
  return { socialTxt: getSocialMediaTxtSelector(state, true) };
})(_IMessage);
