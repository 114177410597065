import React from "react";
import {
  Button,
  TextField,
  InputAdornment,
  useMediaQuery,
  Container,
} from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { HashLinkRef, MatchingCircle } from "..";
import { donationActions } from "../../state/donation/actions";
import { formatMoneyInput, formatImpact, isMatchingCampaign } from "../../lib";
import { matchingBarStyles } from "./MatchingBar.styles";
import classNames from "classnames";
import { getUrls } from "../../state";

export function _MatchingBar(props) {
  const {
    campaign: {
      campaign_type_id,
      matching_times,
      isBonusRound,
      bonus_matching_times,
      isBonusMatching,
      isActive,
      startingSoon,
      hide_stats_component,
      homeUrl,
      ended,
    },
    matchingDonationSyncAmount,
    donationAmount,
  } = props;

  const matchingX = isBonusRound ? bonus_matching_times : matching_times;
  const isMatching = !isBonusRound || isBonusMatching ? true : false;
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const classes = matchingBarStyles({ isMatching });
  const dispatch = useDispatch();

  const handleDonationChange = e => {
    const formattedVal = formatMoneyInput(e.target.value);
    dispatch(donationActions.setMatchingDonationSyncAmount(formattedVal));
    dispatch(donationActions.matchingBarDonationChange(formattedVal));
  };

  const DonateButton = () => (
    <div className={classes.buttonWrapper}>
      <Button
        component={HashLinkRef}
        to={`${homeUrl}#donate`}
        disabled={!isActive}
        variant="contained"
        color="primary"
        size="large"
        className={classes.button}
      >
        Donate now
      </Button>
    </div>
  );

  if (ended || startingSoon || !isMatchingCampaign(campaign_type_id)) {
    return <></>;
  }
  if (!isMatching) {
    return (
      <div className={classes.matchReached}>
        <div className={classes.reachedMsg}>
          Match amount reached but every donation is still appreciated!
        </div>
        {hide_stats_component && <DonateButton />}
      </div>
    );
  }

  return (
    <Container>
      <div className={classes.container}>
        {isMobile && (
          <div className={classes.matchBanner}>
            Every dollar matched
            <div className={classes.xCircle}>X{matchingX}</div>
          </div>
        )}
        <div className={classes.group}>
          <div className={classes.subheader}>Your donation</div>
          <TextField
            className={classes.input}
            InputProps={{
              autoComplete: "off",
              startAdornment: (
                <InputAdornment position="start">
                  <span className={classes.adornment}>$</span>
                </InputAdornment>
              ),
              classes: { input: classes.inputTxt },
            }}
            type="number"
            size={isMobile ? "small" : "medium"}
            value={matchingDonationSyncAmount}
            onChange={handleDonationChange}
          />
        </div>

        {!isMobile && (
          <div className={classes.matchingCircleContainer}>
            <MatchingCircle />
            <div className={classes.xTimes}>X{matchingX}</div>
          </div>
        )}

        <div className={classNames(classes.group, classes.impactGroup)}>
          <div className={classes.subheader}>Your impact</div>
          <div className={classes.impact}>
            <div>$</div>
            <div className={classes.impactAmount}>
              {formatImpact(donationAmount, matchingX)}
            </div>
          </div>
        </div>
      </div>
      {hide_stats_component && <DonateButton />}
    </Container>
  );
}

export const MatchingBar = connect(state => {
  const {
    campaign,
    donation: { matchingDonationSyncAmount, donationAmount },
  } = state;
  const { homeUrl } = getUrls(state);
  return { campaign, matchingDonationSyncAmount, donationAmount, homeUrl };
})(_MatchingBar);
