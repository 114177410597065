import React, { useState, useEffect, Fragment } from "react";
import { Button, Container, TextField, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import LinkIcon from "@material-ui/icons/Link";
import LooksOneIcon from "@material-ui/icons/LooksOne";
import { MainBanner, UnderBannerHeader } from "..";
import { APIClient, isProductCampaign } from "../../lib";

function _VolunteerPagesOnly({
  campaign_type_id,
  campaignId,
  volunteer_label,
}) {
  const classes = styles();
  const [volunteerId, setVolunteerId] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [action, setAction] = useState("");
  const [actionPlural, setActionPlural] = useState("");
  const [volunteerLbl, setVolunteerLbl] = useState("");
  const [volunteerPossessive, setVolunteerPossessive] = useState("");

  useEffect(() => {
    const isProduct = isProductCampaign(campaign_type_id);
    setAction(isProduct ? "purchase" : "donate");
    setActionPlural(isProduct ? "purchases" : "donations");
    setVolunteerLbl(volunteer_label);
    setVolunteerPossessive(`${volunteer_label}'s`);
  }, [campaign_type_id, volunteer_label]);

  const onVolunteerIdChange = e => {
    setVolunteerId(e.target.value);
    setErrorMsg("");
  };

  const handleLookupVolunteer = async () => {
    if (!volunteerId) return setErrorMsg("Please enter a valid ID");
    setSubmitting(true);
    const response = await APIClient.get(
      `/links/volunteer_webpage/${volunteerId}?campaign_id=${campaignId}`,
    );
    const { error, code, data } = response;
    if (error) {
      let msg = "Please try again later";
      if (code === 404) msg = "Invalid ID, please check and try again";
      setErrorMsg(msg);
      setSubmitting(false);
    } else {
      const a = document.createElement("a");
      a.setAttribute("href", data.link);
      a.click();
    }
  };

  return (
    <Fragment>
      <MainBanner />
      <UnderBannerHeader />
      <Container>
        <div className={classes.wrapper}>
          <div className={classes.container}>
            <h1 className={classes.header}>Two ways to {action}</h1>
            <h2 className={classes.subheader}>
              <span className={classes.capitalize}>{actionPlural}</span> need to
              be made on the {volunteerPossessive} personal page.
            </h2>
            <h2 className={classes.subheader}>
              There are two ways to get to a {volunteerPossessive} page.
            </h2>
            <div className={classes.optionBoxes}>
              <div className={classes.optionBox}>
                <div className={classes.iconContainer}>
                  <LinkIcon color="primary" />
                </div>
                <h3 className={classes.optionTittle}>
                  <span className={classes.capitalize}>
                    {volunteerPossessive}
                  </span>{" "}
                  personal web link
                </h3>
                <p className={classes.optionTxt}>
                  Reach out to the {volunteerLbl} and follow the link they share
                  with you to {action}
                </p>
              </div>
              <div className={classes.optionBox}>
                <div className={classes.iconContainer}>
                  <LooksOneIcon color="primary" />
                </div>
                <h3 className={classes.optionTittle}>
                  <span className={classes.capitalize}>
                    {volunteerPossessive}
                  </span>{" "}
                  personal ID number
                </h3>
                <p className={classes.optionTxt}>
                  Reach out to the {volunteerLbl} and enter their personal{" "}
                  {volunteerLbl} ID below to {action}
                </p>
              </div>
            </div>
            <div className={classes.lookupBox}>
              <h3 className={classes.lookupTitle}>
                <span className={classes.capitalize}>{volunteerLbl}</span>{" "}
                lookup
              </h3>
              <div className={classes.lookupTxt}>
                Start by entering your {volunteerPossessive} personal ID number
              </div>
              <div className={classes.inputAndSearch}>
                <TextField
                  placeholder={`Enter ${volunteerLbl} ID`}
                  className={classes.input}
                  value={volunteerId}
                  type="number"
                  onChange={onVolunteerIdChange}
                  helperText={errorMsg}
                  error={Boolean(errorMsg)}
                />
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleLookupVolunteer}
                  disabled={submitting}
                >
                  Go to {volunteerLbl}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  );
}

export const VolunteerPagesOnly = connect(state => {
  const {
    campaign_type_id,
    campaignId,
    volunteer_label = "student",
  } = state.campaign;
  return { campaign_type_id, campaignId, volunteer_label };
})(_VolunteerPagesOnly);

const styles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "0 16px",
  },
  container: {
    width: 816,
    maxWidth: "100%",
    margin: "40px 0",
  },
  header: {
    marginBottom: 8,
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },
  subheader: {
    fontSize: 16,
    letterSpacing: 0.2,
    lineHeight: "20px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      lineHeight: "18px",
    },
  },
  optionBoxes: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 24,
    marginBottom: 40,
  },
  optionBox: {
    width: 396,
    maxWidth: "48%",
    padding: "24px 6px",
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  optionTittle: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    lineHeight: "23px",
    color: theme.palette.primary.main,
    textAlign: "center",
    margin: "12px 0",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      lineHeight: "17px",
    },
  },
  optionTxt: {
    fontSize: 14,
    letterSpacing: 0.17,
    lineHeight: "18px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      lineHeight: "16px",
    },
  },
  lookupBox: {
    backgroundColor: theme.palette.background.light,
    padding: "40px 16px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  lookupTitle: {
    marginBottom: 8,
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0.3,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },
  lookupTxt: {
    fontSize: 16,
    lineHeight: "19px",
    letterSpacing: 0,
    textAlign: "center",
    marginBottom: 24,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      lineHeight: "17px",
    },
  },
  inputAndSearch: {
    display: "flex",
    justifyContent: "space-between",
    width: 522,
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  input: {
    width: 316,
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 24,
      width: "100%",
    },
  },
  button: {
    width: 192,
    borderRadius: 28,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    maxHeight: 56,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  iconContainer: {
    width: 44,
    height: 44,
    borderRadius: "100%",
    backgroundColor: theme.palette.background.light,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  capitalize: {
    textTransform: "capitalize",
  },
}));
