import React from "react";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { LinkSearchCard } from "../components";
import {
  APP_ASSET_BASE_URL,
  homeLogoPrefixId,
  hostTitle,
  homeImagePrefixId,
  homeBackgroundColor,
} from "../lib";

export function Home() {
  const desktopBackgroundImage = `${APP_ASSET_BASE_URL}/${homeImagePrefixId}_home-desktop.png`;
  const mobileBackgroundImage = `${APP_ASSET_BASE_URL}/${homeImagePrefixId}_home-mobile.png`;
  const classes = styles({ desktopBackgroundImage, homeBackgroundColor });
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

  return (
    <div className={classes.container}>
      <div className={classes.logoWrapper}>
        <img
          className={classes.logo}
          src={`${APP_ASSET_BASE_URL}/${homeLogoPrefixId}_home-logo.png`}
          alt="logo"
        />
      </div>

      <div className={classes.cardContainer}>
        <div className={classes.cards}>
          <LinkSearchCard
            label="Register"
            placeholder="Enter campaign ID"
            url="/links/volunteer_registration/"
            text="Enter your group's campaign ID to register to fundraise/sell"
          />
          <LinkSearchCard
            label="Shop Now"
            placeholder="Enter seller ID"
            url="/links/volunteer_webpage/"
            text="Enter the ID of the seller you would like to support"
          />
          <LinkSearchCard
            label="Seller Login"
            placeholder="Enter seller ID"
            url="/links/volunteer_login/"
            text="Enter your seller ID to login to your dashboard"
          />
          <LinkSearchCard
            label="Sponsor Login"
            placeholder="Enter email"
            url="/links/org_login?email="
            text="Enter your email address associated with your sponsor account"
            emailSearch
          />
        </div>
      </div>

      {isMobile && (
        <img
          className={classes.mobileImg}
          src={mobileBackgroundImage}
          alt="bgImage"
        />
      )}

      <Helmet>
        <title>{hostTitle}</title>
        <meta property="og:title" content={hostTitle} />
        <meta
          name="description"
          content={`Register/Login/Shop/Support with a ${hostTitle} fundraising campaign`}
        />
        <meta
          property="og:description"
          content={`Register/Login/Shop/Support with a ${hostTitle} fundraising campaign`}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={desktopBackgroundImage} />
        <meta name="twitter:card" content="summary"></meta>
      </Helmet>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    backgroundImage: ({ desktopBackgroundImage }) =>
      `url(${desktopBackgroundImage})`,
    minHeight: "100vh",
    width: "100vw",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "bottom center",
    paddingTop: 24,
    [theme.breakpoints.down("xs")]: {
      backgroundImage: () => "none !important",
      paddingBottom: 0,
      backgroundColor: ({ homeBackgroundColor }) => homeBackgroundColor,
    },
  },
  logoWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: 16,
  },
  logo: { maxHeight: 100, maxWidth: "95%", padding: 0, margin: 0 },
  cardContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  cards: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  mobileImg: {
    width: "100%",
    padding: 0,
    margin: 0,
  },
}));
