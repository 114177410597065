import React, { Fragment } from "react";
import { Container, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import {
  RulesAndDisclaimers,
  ParticipantContainer,
  DonationForm,
  DonationConfirmation,
  SocialMediaBar,
  CampaignStats,
  MainBanner,
  Sponsors,
  Breadcrumbs,
  UnderBannerHeader,
  Showcase,
  PictureAndParagraph,
} from "../components";

function _VolunteerPage(props) {
  const {
    campaign: {
      volunteerId,
      volunteer_header_suffix,
      picture: orgPicture,
      volunteerPicture,
      no_volunteer_pictures,
      display_text_html,
      first_name = "",
      last_name = "",
      familyMemberStr,
      hide_stats_component,
      volunteer_video_link,
      video_link: orgVideoLink,
    },
    donationSuccess,
  } = props;
  const headerSuffix = volunteer_header_suffix
    ? volunteer_header_suffix
    : "'s donation page";
  const video_link =
    volunteer_video_link && !no_volunteer_pictures
      ? volunteer_video_link
      : orgVideoLink;
  const picture = volunteerPicture ? volunteerPicture : orgPicture;

  const classes = styles({ hide_stats_component });

  if (!volunteerId) return <></>;
  if (donationSuccess) return <DonationConfirmation />;
  return (
    <Fragment>
      <MainBanner forVolunteerPage={true} />
      <UnderBannerHeader />
      <Breadcrumbs forVolunteer={true} />

      <Container>
        <div className={classes.titleContainer}>
          <h1 className={classes.title}>
            {`${first_name} ${last_name}${headerSuffix}`}
          </h1>
          {familyMemberStr && (
            <div className={classes.familyMembers}>
              Family Plan with {familyMemberStr}
            </div>
          )}
        </div>
      </Container>

      <PictureAndParagraph
        picture={picture}
        video_link={video_link}
        text_html={display_text_html}
      />
      <CampaignStats forVolunteer={true} />
      <Showcase />
      <Sponsors />
      <ParticipantContainer forVolunteer={true} />
      <DonationForm forVolunteer={true} />
      <RulesAndDisclaimers />
      <SocialMediaBar />
    </Fragment>
  );
}

export const VolunteerPage = connect(state => {
  const {
    campaign,
    donation: { donationSuccess },
  } = state;
  return { campaign, donationSuccess };
})(_VolunteerPage);

const styles = makeStyles(theme => ({
  titleContainer: {
    padding: "32px 0 12px 0",
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.only("md")]: {
      padding: "32px 16px 12px 16px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "32px 16px 0 16px",
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 32,
    letterSpacing: 0.4,
    lineHeight: "36px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      letterSpacing: 0.3,
      lineHeight: "26px",
    },
  },
  familyMembers: {
    marginTop: 6,
    textAlign: "center",
    fontSize: 18,
    lineHeight: "20px",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "18px",
    },
  },
}));
