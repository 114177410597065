import React, { useEffect, useRef, useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import {
  isProductCampaign,
  gatewayProviderIds,
  AUTHORIZE_DON_NET_EL_ID,
  faviconPrefixId,
  APP_ASSET_BASE_URL,
} from "../../lib";
import { Elements } from "@stripe/react-stripe-js";
import { uiActions } from "../../state";

function _SiteWrapper(props) {
  const {
    children,
    google_analytics_key,
    google_tag_manager_key,
    facebook_pixel_id,
    gatewayProviderId,
    gatewayPublicKey,
    glk,
    campaign_type_id,
    location: { search },
  } = props;
  const ADNInterval = useRef(null);
  const ckScriptAdded = useRef(false);
  const banquestScriptAdded = useRef(false);
  const isProduct = isProductCampaign(campaign_type_id);
  const dispatch = useDispatch();
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    const icon32 = document.getElementById("icon32");
    icon32.href = `${APP_ASSET_BASE_URL}/${faviconPrefixId}_favicon-32x32.png`;
    const icon16 = document.getElementById("icon16");
    icon16.href = `${APP_ASSET_BASE_URL}/${faviconPrefixId}_favicon-16x16.png`;
    const ATI = document.getElementById("apple-touch-icon");
    ATI.href = `${APP_ASSET_BASE_URL}/${faviconPrefixId}_apple-touch-icon.png`;
  }, []);

  // Authorize.net iframe
  useEffect(() => {
    if (gatewayProviderId === gatewayProviderIds.AUTHORIZE_DOT_NET) {
      ADNInterval.current = setInterval(() => {
        const ADNContainer = document.getElementById(AUTHORIZE_DON_NET_EL_ID);
        if (ADNContainer) {
          const tag = document.createElement("script");
          tag.src = `${process.env.REACT_APP_AUTHORIZEDOTNET_IFRAME_BASE_URL}/v3/AcceptUI.js`;
          document.head.appendChild(tag);
          window.authorizeDotNetFakeResHandler = () => {};
          clearInterval(ADNInterval.current);
        }
      }, 100);
    }
    return () => {
      clearInterval(ADNInterval.current);
    };
  }, [gatewayProviderId]);

  // Stripe iframe
  useEffect(() => {
    const stripeLoader = async () => {
      const { loadStripe } = await import("@stripe/stripe-js");
      setStripePromise(loadStripe(gatewayPublicKey));
    };
    if (gatewayProviderId === gatewayProviderIds.STRIPE && gatewayPublicKey) {
      stripeLoader();
    }
  }, [gatewayProviderId, gatewayPublicKey]);

  // UsaEpay iframe
  useEffect(() => {
    if (gatewayProviderId === gatewayProviderIds.USA_EPAY && gatewayPublicKey) {
      const tag = document.createElement("script");
      tag.src = process.env.REACT_APP_USAEPAY_SCRIPT_URL;
      document.head.appendChild(tag);
    }
  }, [gatewayProviderId, gatewayPublicKey]);

  // Cardknox iframe
  useEffect(() => {
    if (
      gatewayProviderId === gatewayProviderIds.CARDKNOX &&
      !ckScriptAdded.current
    ) {
      ckScriptAdded.current = true;
      const tag = document.createElement("script");
      tag.src =
        "https://cdn.cardknox.com/ifields/2.15.2302.0801/ifields.min.js";
      document.head.appendChild(tag);
    }
  }, [gatewayProviderId]);

  // Banquest iframe
  useEffect(() => {
    if (
      gatewayProviderId === gatewayProviderIds.BANQUEST &&
      !banquestScriptAdded.current
    ) {
      banquestScriptAdded.current = true;
      const tag = document.createElement("script");
      tag.src = process.env.REACT_APP_BANQUEST_SCRIPT_URL;
      document.head.appendChild(tag);
    }
  }, [gatewayProviderId]);

  useEffect(() => {
    const { a: affiliate } = queryString.parse(search);
    if (affiliate) {
      dispatch(uiActions.setAffiliate(affiliate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  // Lucky Orange
  // useEffect(() => {
  //   if (process.env.REACT_APP_ENV === "production") {
  //     const tag = document.createElement("script");
  //     tag.src = "https://tools.luckyorange.com/core/lo.js?site-id=3f774897";
  //     tag.defer = true;
  //     document.head.appendChild(tag);
  //   }
  // }, []);

  return (
    <Fragment>
      {google_analytics_key && (
        <Helmet
          script={[
            {
              type: "text/javascript",
              innerHTML: `window.dataLayer = window.dataLayer || [];function gtag() { dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', '${google_analytics_key}');`,
            },
          ]}
        />
      )}

      {google_analytics_key && (
        <Helmet>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${google_analytics_key}`}
          ></script>
        </Helmet>
      )}

      {facebook_pixel_id && (
        <Helmet
          script={[
            {
              type: "text/javascript",
              innerHTML: `!function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${facebook_pixel_id}');
              fbq('track', 'PageView');`,
            },
          ]}
          noscript={[
            {
              innerHTML: `<img height="1" width="1" style="display: none" src="https://www.facebook.com/tr?id=${facebook_pixel_id}&ev=PageView&noscript=1"/>`,
            },
          ]}
        />
      )}

      {google_tag_manager_key && (
        <Helmet
          script={[
            {
              type: "text/javascript",
              innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${google_tag_manager_key}')`,
            },
          ]}
        />
      )}

      {google_tag_manager_key && (
        <noscript>
          <iframe
            title="GTM"
            src={`https://www.googletagmanager.com/ns.html?id=${google_tag_manager_key}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
      )}

      <button
        hidden
        id={AUTHORIZE_DON_NET_EL_ID}
        className="AcceptUI"
        data-billingaddressoptions='{"show":false, "required":false}'
        data-apiloginid={glk}
        data-clientkey={gatewayPublicKey}
        data-acceptuiformbtntxt={isProduct ? "Place order" : "Submit"}
        data-acceptuiformheadertxt="Enter your payment information"
        data-responsehandler="authorizeDotNetFakeResHandler"
      />

      <Elements stripe={stripePromise}>{children && { ...children }} </Elements>
    </Fragment>
  );
}

function mapStateToProps(state) {
  return { ...state.campaign };
}

export const SiteWrapper = withRouter(connect(mapStateToProps)(_SiteWrapper));
