import { uiTypes } from "./actions";
const initialState = {
  loading: false,
  errorMessage: null,
  sessionEnded: false,
  affiliate: null,
};

const uiReducers = (state = initialState, action) => {
  switch (action.type) {
    case uiTypes.UI_LOADING:
      return { ...state, loading: true };
    case uiTypes.UI_LOADING_RESET:
      return { ...state, loading: false };
    case uiTypes.UI_DATA_FETCH_ERROR:
      return { ...state, errorMessage: action.errorMessage };
    case uiTypes.UI_TOAST_SET:
      return { ...state, errorMessage: action.toast };
    case uiTypes.UI_TOAST_RESET:
      return { ...state, errorMessage: null };
    case uiTypes.SESSION_ENDED_SET:
      return { ...state, sessionEnded: true };
    case uiTypes.AFFILIATE_SET:
      return { ...state, affiliate: action.affiliate };
    default:
      return state;
  }
};

export { uiReducers };
