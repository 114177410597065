import React, { useState, Fragment } from "react";
import {
  AppBar,
  Button,
  SwipeableDrawer,
  Toolbar,
  IconButton,
  useMediaQuery,
  Container,
  Badge,
  useScrollTrigger,
  Link as MuiLink,
} from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  donationActions,
  volunteerActions,
  productActions,
  submissionActions,
  getUrls,
} from "../state";
import classnames from "classnames";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Link } from "react-router-dom";
import { HashLinkRef } from "../components";
import {
  PRODUCTS_EL_ID,
  DONATE_EL_ID,
  isBigKahunaCompany,
  campaignTypeBooleans,
} from "../lib";
import { headerStyles } from "./Header.styles";

function _Header(props) {
  const {
    companyId,
    campaign_type_id,
    logo,
    allowProductDonations,
    orderItemCount,
    homeUrl,
    registerUrl,
    loginUrl,
    donationSuccess,
    actions: {
      resetDonation,
      resetVolunteerRegistrationForm,
      resetCart,
      openSubmissionDialog,
    },
  } = props;
  const notOnTop = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const [menuOpen, toggleMenu] = useState(false);
  // it takes time for useMediaQuery to kick in so we can't just use one var with its opposite, because you will see a flash of the other
  const lgDevice = useMediaQuery(theme => theme.breakpoints.up("md"));
  const smallDevice = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const { isRaffle, isDonation, isMatching, isProduct } = campaignTypeBooleans(
    campaign_type_id,
  );
  const isBigKahuna = isBigKahunaCompany(companyId);
  const classes = headerStyles({ notOnTop, isProduct });

  const handleLinkClick = () => {
    closeMenu();
    resetDonation();
    resetCart();
    resetVolunteerRegistrationForm();
  };

  const closeMenu = () => {
    if (smallDevice) toggleMenu(false);
  };

  const Cart = () => {
    if (!isProduct) return <></>;
    return (
      <IconButton
        edge="end"
        size="small"
        className={classes.cart}
        component={HashLinkRef}
        to={`${homeUrl}#cart`}
      >
        <Badge
          badgeContent={donationSuccess ? 0 : orderItemCount}
          color="primary"
          overlap="rectangular"
        >
          <ShoppingCartIcon />
        </Badge>
      </IconButton>
    );
  };

  const Logo = () => (
    <div className={classes.logoContainer}>
      {logo && <img className={classes.logo} src={logo} alt="Org logo" />}
    </div>
  );

  return (
    <AppBar className={classes.appBar} elevation={0}>
      <Container>
        <Toolbar className={classes.toolbar}>
          {/* DESKTOP */}
          {lgDevice && (
            <Fragment>
              <Logo />

              <div>
                <Button
                  component={HashLinkRef}
                  to={`${homeUrl}#top`}
                  className={classes.navButton}
                  onClick={handleLinkClick}
                >
                  Home
                </Button>
                <Button
                  component={Link}
                  to={`${registerUrl}`}
                  className={classes.navButton}
                  onClick={handleLinkClick}
                >
                  Register
                </Button>
                {!isBigKahuna && (
                  <Button
                    className={classes.navButton}
                    onClick={() => openSubmissionDialog("org")}
                  >
                    Contact
                  </Button>
                )}
                <Button
                  href={loginUrl}
                  className={classes.navButton}
                  onClick={handleLinkClick}
                >
                  Login
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={HashLinkRef}
                  to={`${homeUrl}#${isProduct ? PRODUCTS_EL_ID : DONATE_EL_ID}`}
                  className={classes.donateButton}
                  size="medium"
                  onClick={handleLinkClick}
                >
                  {isProduct ? "Shop" : "Donate"}
                </Button>
                {isProduct && allowProductDonations && (
                  <Button
                    variant="contained"
                    color="secondary"
                    component={HashLinkRef}
                    to={`${homeUrl}#${DONATE_EL_ID}`}
                    className={classes.donateButton}
                    onClick={handleLinkClick}
                  >
                    Donate
                  </Button>
                )}
                <Cart />
              </div>
            </Fragment>
          )}

          {/* MOBILE */}
          {smallDevice && (
            <Fragment>
              <div className={classes.mobileMenuAndLogo}>
                <IconButton
                  edge="start"
                  className={classes.menuIcon}
                  onClick={() => toggleMenu(true)}
                >
                  <MenuIcon />
                </IconButton>
                {(isRaffle || isDonation || isMatching) && <Logo />}
              </div>

              {isProduct && <Logo />}

              <Cart />

              {(isRaffle || isDonation || isMatching) && (
                <Button
                  variant="contained"
                  color="primary"
                  component={HashLinkRef}
                  to={`${homeUrl}#${DONATE_EL_ID}`}
                  className={classes.mobileHeaderDonate}
                  onClick={handleLinkClick}
                >
                  Donate
                </Button>
              )}
            </Fragment>
          )}
        </Toolbar>
      </Container>

      {smallDevice && (
        <SwipeableDrawer
          anchor="bottom"
          open={menuOpen}
          onClose={() => toggleMenu(false)}
          onOpen={() => toggleMenu(true)}
          transitionDuration={500}
        >
          <div className={classes.mobileMenuContainer}>
            <div>
              <div className={classes.menuCloseContainer}>
                <IconButton
                  edge="start"
                  className={classes.menuClose}
                  onClick={() => toggleMenu(false)}
                >
                  <CloseIcon fontSize="medium" />
                </IconButton>
              </div>
              <div className={classes.mobileTopLinks}>
                <MuiLink
                  component={HashLinkRef}
                  to={`${homeUrl}#top`}
                  className={classes.mobileLink}
                  onClick={handleLinkClick}
                >
                  Home
                </MuiLink>
                <MuiLink
                  component={Link}
                  to={registerUrl}
                  className={classes.mobileLink}
                  onClick={handleLinkClick}
                >
                  Register
                </MuiLink>
                {!isBigKahuna && (
                  <div
                    className={classes.mobileLink}
                    onClick={() => {
                      closeMenu();
                      openSubmissionDialog("org");
                    }}
                  >
                    Contact
                  </div>
                )}
                <MuiLink
                  href={loginUrl}
                  className={classes.mobileLink}
                  onClick={handleLinkClick}
                >
                  Login
                </MuiLink>
              </div>
            </div>

            <div>
              <Button
                variant="contained"
                color="primary"
                component={HashLinkRef}
                to={`${homeUrl}#${isProduct ? PRODUCTS_EL_ID : DONATE_EL_ID}`}
                className={classes.mobileDonateButton}
                size="large"
                onClick={handleLinkClick}
              >
                {isProduct ? "Shop" : "Donate"}
              </Button>
              {isProduct && allowProductDonations && (
                <Button
                  variant="contained"
                  color="secondary"
                  component={HashLinkRef}
                  to={`${homeUrl}#${DONATE_EL_ID}`}
                  className={classnames(
                    classes.mobileDonateButton,
                    classes.mobileBottomButton,
                  )}
                  size="large"
                  onClick={handleLinkClick}
                >
                  Donate
                </Button>
              )}
            </div>
          </div>
        </SwipeableDrawer>
      )}
    </AppBar>
  );
}

export const Header = connect(
  state => {
    const {
      campaign: { companyId, campaign_type_id, logo, allow_donations },
      product: { orderItemCount },
      donation: { donationSuccess },
    } = state;
    const { homeUrl, registerUrl, loginUrl } = getUrls(state);
    return {
      companyId,
      campaign_type_id,
      logo,
      orderItemCount,
      homeUrl,
      registerUrl,
      loginUrl,
      donationSuccess,
      allowProductDonations: allow_donations,
    };
  },
  dispatch => {
    return {
      actions: {
        ...bindActionCreators(volunteerActions, dispatch),
        ...bindActionCreators(donationActions, dispatch),
        ...bindActionCreators(productActions, dispatch),
        ...bindActionCreators(submissionActions, dispatch),
      },
    };
  },
)(_Header);
