import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";

function _VolunteerRegistrationLogin({
  orgSlug,
  campaignId,
  volunteerUserEmail,
  volunteerUserSlug,
  allow_associated_volunteer_accounts,
}) {
  const classes = styles();
  const loginUrl = `${process.env.REACT_APP_ADMIN_URL}/login?slug=${orgSlug}&e=${volunteerUserEmail}&volunteer_slug=${volunteerUserSlug}&campaignId=${campaignId}`;

  return (
    <div className={classes.containerWrapper}>
      <div className={classes.container}>
        <div className={classes.instructions}>
          You are already registered for this campaign.
        </div>
        <div className={classes.instructions}>Please proceed to login.</div>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          target="_blank"
          href={loginUrl}
        >
          Go to login
        </Button>

        {allow_associated_volunteer_accounts && (
          <div className={classes.addUserContainer}>
            <div className={classes.instructions}>
              To register a NEW user with this email address,
            </div>
            <div className={classes.instructions}>
              login to your existing account.
            </div>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              target="_blank"
              href={`${process.env.REACT_APP_ADMIN_URL}/add_associated_volunteer_account?register_campaign_id=${campaignId}&slug=${orgSlug}`}
            >
              Login and add account
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export const VolunteerRegistrationLogin = connect(state => {
  const {
    campaign: {
      slug: orgSlug,
      campaignId,
      allow_associated_volunteer_accounts,
    },
    volunteer: { volunteerUserEmail, volunteerUserSlug },
  } = state;
  return {
    orgSlug,
    campaignId,
    volunteerUserEmail,
    volunteerUserSlug,
    allow_associated_volunteer_accounts,
  };
})(_VolunteerRegistrationLogin);

const styles = makeStyles(theme => ({
  instructions: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.2,
    lineHeight: "19px",
    textAlign: "center",
  },
  containerWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: 700,
    maxWidth: "100%",
  },
  button: {
    height: 56,
    width: "100%",
    marginTop: 18,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    borderRadius: 28,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  addUserContainer: {
    marginTop: 42,
  },
}));
