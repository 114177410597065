import React, { useState, useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import classnames from "classnames";
import { Container, useMediaQuery } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { donationActions, productActions, setToast } from "../../state";
import { format } from "date-fns";
import {
  ProductsContainer,
  Tickets,
  RegDonationInfo,
  RegDonationInfoWithGroupItems,
  MatchingInfo,
  ProductCart,
  ProductDonation,
  TaxChangeObserver,
  ProductSuggestions,
  DonationChangeObserver,
  DonationSummaryAndSubmit,
  CustomerInfo,
  PaymentDialog,
  RecaptchaV2Challenge,
  MobileMiniOrderSummary,
  OrderSummaryAndActions,
} from "..";
import {
  campaignTypeBooleans,
  // PO_BOX_REGEX,
  CC_PAYMENT,
  RETRY_ABLE_DONATION_CODE,
  RECAPTCHA_FAILURE_CODE,
  RECAPTCHA_FAILURE_CAN_RETRY_CODE,
  INVALID_ACTION_CODE,
  convertGiftCardObjArrToCodeStrArr,
  customFormValidation,
  ccEntryModes,
  getUserTimezone,
  DONATE_EL_ID,
} from "../../lib";
import { donationFormStyles } from "./DonationForm.styles";

function _DonationForm(props) {
  const {
    campaign,
    campaign: {
      campaign_type_id,
      companyId,
      campaignId,
      end_date,
      volunteerId,
      teamId,
      campaign_donation_group_id,
      isActive,
      canadianCampaign,
      ended,
    },
    ccEntryMode,
    actions,
    productActions,
    forVolunteer,
    selectedProducts,
    shipping_options,
    adminIframe,
    forOrgIframe,
    authToken,
    iframeVolunteerId,
    iframeCanSubmitPledges,
    iframeOrgEmail,
    sessionEnded,
    affiliate,
    grandTotal,
    emptyCart,
    no_po_boxes,
  } = props;
  const isGivver = companyId === Number(process.env.REACT_APP_GIVVR_COMPANY_ID);
  const { isRaffle, isDonation, isMatching, isProduct } = campaignTypeBooleans(
    campaign_type_id,
  );
  const dispatch = useDispatch();
  const isWideScreen = useMediaQuery(theme => theme.breakpoints.up("lg"));
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [initialValues, setInitialValues] = useState({});
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [adminVolunteerId, setAdminVolunteerId] = useState(null);
  const [showV2Recaptcha, setShowV2Recaptcha] = useState(false);
  const [twoColLayout, setTwoColLayout] = useState(null);
  const classes = donationFormStyles({ isGivver, twoColLayout, isProduct });

  useEffect(() => {
    let IV = {
      pmt_type: CC_PAYMENT,
      installments: isMatching || isDonation ? 0 : null,
    };
    if (isProduct) {
      IV = {
        ...IV,
        shipping_same_as_billing: true,
        ship_to_school: shipping_options === "school",
      };
    }
    setInitialValues(IV);
  }, [isProduct, isMatching, isDonation, shipping_options]);

  useEffect(() => {
    setTwoColLayout(isWideScreen && isProduct);
  }, [isWideScreen, isProduct]);

  const sendSuccessToParent = donationId => {
    window.top.postMessage(
      JSON.stringify({
        success: true,
        donationId,
      }),
      process.env.REACT_APP_ADMIN_URL,
    );
    actions.resetDonation();
    productActions.resetCart();
  };

  const noCustomValidationErrors = formValues => {
    return customFormValidation(
      campaign,
      formValues,
      selectedProducts,
      dispatch,
      no_po_boxes,
    );
  };

  const validateForm = form => {
    const { hasValidationErrors, values } = form.getState();
    if (hasValidationErrors) {
      form.submit(); // trigger submit to show errors (TODO: see if there is a better way to do this)
      dispatch(setToast("Please fill out all required info."));
      return false;
    }
    if (!noCustomValidationErrors(values)) return false;
    return true;
  };

  const validateFormAndOpenPayment = form => {
    if (validateForm(form)) setShowPaymentForm(true);
  };

  const onSubmit = async (values, form) => {
    if (sessionEnded || !isActive) return;
    if (!noCustomValidationErrors(values)) return { disableRetry: false };

    const {
      amount,
      customAmount,
      selectedDonationGroupItems,
      title,
      tip,
      processing_fee,
      giftCards,
      recurringTotal,
      ...rest
    } = values;
    const timezone = getUserTimezone();
    // donation amount without any fees/tips/processing_fee/ticket etc.
    const donationAmount = recurringTotal
      ? Number(recurringTotal)
      : Number(amount || 0) + Number(customAmount || 0);
    const donation = {
      campaign_id: campaignId,
      amount: donationAmount ? `${donationAmount}` : null,
      tip: tip ? `${tip}` : null,
      processing_fee: processing_fee ? `${processing_fee}` : null,
      canadianCampaign,
      title: !title || title === "N/A" ? null : title,
      timezone,
      affiliate,
      grandTotal,
      ...rest,
    };
    if (giftCards) {
      donation.gift_cards = convertGiftCardObjArrToCodeStrArr(giftCards);
    }

    if (isProduct) {
      donation.selected_products = Object.keys(selectedProducts).map(
        product_id => {
          const { qty, personalization } = selectedProducts[product_id];
          const formatted = { product_id: Number(product_id), qty };
          if (Array.isArray(personalization)) {
            formatted.personalization = personalization;
          }
          return formatted;
        },
      );
    }

    if (selectedDonationGroupItems) {
      donation.selectedDonationGroupItems = Object.keys(
        selectedDonationGroupItems,
      ).map(id => {
        const { dayIds } = selectedDonationGroupItems[id];
        return { id: Number(id), dayIds: dayIds ? dayIds : null };
      });
    }

    // add volunteer_id
    if (volunteerId) donation.volunteer_id = volunteerId;
    else if (iframeVolunteerId) {
      donation.volunteer_id = Number(iframeVolunteerId);
    } else if (adminVolunteerId) {
      donation.volunteer_id = Number(adminVolunteerId);
    }
    // add team_id (TODO: see if this is still used it might be pulled from volunteer on server)
    if (teamId) donation.team_id = teamId;
    // ccEntryMethod
    if (ccEntryMode === ccEntryModes.SWIPE) {
      donation.ccEntryMode = ccEntryModes.SWIPE;
    }
    // recaptcha - if it's using the V2 challenge the token is inserted in the modal
    if (!values.v2RecaptchaValidation) {
      if (!executeRecaptcha) {
        dispatch(
          setToast(
            "There was an error loading Recaptcha, please refresh your page",
          ),
        );
        return;
      }
      const token = await executeRecaptcha("donationSubmit");
      donation.recaptchaToken = token;
    }

    const res = await actions.submitDonation(donation, authToken);

    if (res.error) {
      const { errorMessage, code, errorData } = res;
      if (code === RECAPTCHA_FAILURE_CAN_RETRY_CODE) {
        setShowV2Recaptcha(true);
        form.change(
          "failed_recaptcha_response_id",
          errorData.failed_recaptcha_response_id,
        );
        return { disableRetry: false };
      }
      const disableRetry = code === RETRY_ABLE_DONATION_CODE ? false : true;
      const toastMsg = `${errorMessage}${
        disableRetry &&
        code !== RECAPTCHA_FAILURE_CODE &&
        code !== INVALID_ACTION_CODE
          ? `\nPlease refresh your page and try again`
          : ""
      }`;
      dispatch(setToast(toastMsg));
      return { disableRetry };
    }

    if (adminIframe) sendSuccessToParent(res.donationId);
    return { disableRetry: true };
  };

  return (
    <div className={classes.formContainer}>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
        render={props => {
          const {
            handleSubmit,
            submitting,
            values,
            submitErrors: { disableRetry } = {},
            submitSucceeded,
            form,
          } = props;
          const disableSubmit =
            submitting || submitSucceeded || disableRetry ? true : false;
          return (
            <form onSubmit={handleSubmit} style={{ position: "relative" }}>
              {isProduct && (
                <ProductsContainer
                  formValues={values}
                  adminIframe={adminIframe}
                />
              )}
              <div id={DONATE_EL_ID} className={classes.donationHashLink} />
              <div className={classes.formWrapper}>
                {!isActive && !adminIframe && (
                  <div className={classes.overlay}></div>
                )}
                <div
                  className={classnames(
                    classes.form,
                    !isActive ? classes.disabledForm : "",
                  )}
                >
                  {isRaffle && <Tickets formValues={values} />}
                  {isDonation && !campaign_donation_group_id && (
                    <RegDonationInfo formValues={values} />
                  )}
                  {isDonation && campaign_donation_group_id && (
                    <RegDonationInfoWithGroupItems formValues={values} />
                  )}
                  {isMatching && <MatchingInfo formValues={values} />}
                  {isProduct && <ProductDonation />}
                  {isProduct && <TaxChangeObserver />}
                  {isProduct && <ProductSuggestions />}

                  <div className={classes.cusInfoAndCartWrapper}>
                    <Container>
                      <div className={classes.cusInfoAndCartContainer}>
                        {isProduct && !twoColLayout && <ProductCart />}
                        {isProduct && <MobileMiniOrderSummary />}

                        {(!isProduct || twoColLayout || !emptyCart) && (
                          <CustomerInfo
                            twoColLayout={twoColLayout}
                            setAdminVolunteerId={setAdminVolunteerId}
                            forVolunteer={forVolunteer}
                            adminIframe={adminIframe}
                            forOrgIframe={forOrgIframe}
                            authToken={authToken}
                            iframeCanSubmitPledges={iframeCanSubmitPledges}
                          />
                        )}

                        {isProduct && (
                          <div>
                            {twoColLayout && <ProductCart />}
                            <OrderSummaryAndActions
                              disableSubmit={disableSubmit}
                              formValues={values}
                              submitting={submitting}
                              form={form}
                              validateForm={validateForm}
                              validateFormAndOpenPayment={
                                validateFormAndOpenPayment
                              }
                            />
                          </div>
                        )}
                      </div>

                      {!isProduct && (
                        <DonationSummaryAndSubmit
                          {...{
                            submitting,
                            disableSubmit,
                            validateFormAndOpenPayment,
                            forOrgIframe,
                            orgEmail: iframeOrgEmail,
                            formValues: values,
                          }}
                        />
                      )}

                      {ended && (
                        <div className={classes.endedTxt}>
                          Thank you for making our campaign a success!
                        </div>
                      )}

                      {!adminIframe && end_date && (
                        <div className={classes.endDate}>
                          Campaign ends {format(new Date(end_date), "MM/dd/y")}
                        </div>
                      )}
                    </Container>
                  </div>
                </div>
              </div>
              {!isProduct && <DonationChangeObserver formValues={values} />}
              <RecaptchaV2Challenge isOpen={showV2Recaptcha} />
              <PaymentDialog
                setShowPaymentForm={setShowPaymentForm}
                showPaymentForm={showPaymentForm}
                submitting={submitting}
              />
            </form>
          );
        }}
      />
    </div>
  );
}

export const DonationForm = connect(
  state => {
    const {
      campaign,
      product: { selectedProducts, shipping_options, emptyCart, no_po_boxes },
      ui: { sessionEnded, affiliate },
      donation: { ccEntryMode, grandTotal },
    } = state;
    return {
      campaign,
      ccEntryMode,
      selectedProducts,
      shipping_options,
      sessionEnded,
      affiliate,
      grandTotal,
      emptyCart,
      no_po_boxes,
    };
  },
  dispatch => {
    return {
      actions: bindActionCreators(donationActions, dispatch),
      productActions: bindActionCreators(productActions, dispatch),
    };
  },
)(_DonationForm);
