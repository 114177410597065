/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Dialog, Button, useMediaQuery } from "@material-ui/core";
import { privacyStyles } from "./Privacy.styles";

export function PrivacyModal({ isOpen, toggleModal }) {
  const isSmallDevice = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const classes = privacyStyles();
  return (
    <Dialog
      onClose={() => toggleModal(false)}
      open={isOpen}
      maxWidth={false}
      fullScreen={isSmallDevice}
    >
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.title}>Privacy Notice</div>
          <p>
            <span>
              This privacy notice discloses the privacy practices for&nbsp;
            </span>
            <a
              href="http://www.instaraise.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <strong>www.instaraise.com</strong>
            </a>{" "}
            <strong>
              and any other website using InstaRaise platform including this
              website.
            </strong>
            <span>
              This privacy notice applies solely to information collected by
              these websites. It will notify you of the following:
            </span>
          </p>
          <p>
            <strong>&nbsp;Security</strong>
            <span>&nbsp;</span>
          </p>
          <p>
            <span>
              We take precautions to protect your information. When you submit
              sensitive information via the website, your information and used
              only for the purpose intended. Wherever we collect sensitive
              information (such as credit card data), that information is
              encrypted and transmitted to us in a secure way. You can verify
              this by looking for a lock icon in the address bar and looking for
              "https" at the beginning of the address of the Web page. Your
              credit card information is only used for intended one-time
              transaction and in no way saved or stored in our internal server.
              No member of our company or tech partners have access to credit
              card information after transaction is completed.
            </span>
          </p>
          <p>
            <span>
              While we use encryption to protect sensitive information
              transmitted online, we also protect your contact and personal
              (non-financial) information offline. As part of our promotion
              management services, we do receive information collected by
              promotion sponsors or other parties contracted to collect such
              information and use it only to perform our contracted business
              services, such as performing drawings and other administrative
              tasks related to our business. That information typically includes
              name and address, and may include telephone number, email address
              and/or other data. We only use that information to perform our
              administrative services, or in response to a bona fide government
              or legal request. A client's data at all times remains the
              property of the sponsor(s), is used by us only for the promotion
              for which it was provided.
            </span>
          </p>
          <p>
            <span>
              This website is open to registrants from the age of 13 and up.
              Submission of registration is confirmation of the registrant's
              compliance with this age policy. Personal information of children
              ages 12 and under may only be provided by the child's parent or
              guardian.
            </span>
          </p>
          <p>
            <strong>&nbsp;Transactions</strong>
            <span>&nbsp;</span>
          </p>
          <p>
            <span>
              We request information from you on our checkout form. To checkout,
              you must provide contact information and financial information
              (like credit card number, expiration date). The credit card
              information is used to complete that transaction and is not saved
              for any further purpose. Your non-financial personal information
              will only be accessible by your intended sponsor and is solely
              used by them. No names or personal information are ever added to
              lists or sold or rented to others for any purpose.
            </span>
          </p>
          <p>
            <strong>&nbsp;Cookies</strong>
            <span>&nbsp;</span>
          </p>
          <p>
            <span>
              We use "cookies" on this site. A cookie is a piece of data stored
              on a site visitor's hard drive to help us improve your access to
              our site and identify repeat visitors to our site. For instance,
              when we use a cookie to identify you, you would not have to log in
              a password more than once, thereby saving time while on our site.
              Cookies can also enable us to track and target the interests of
              our users to enhance the experience on our site. Usage of a cookie
              is in no way linked to any personally identifiable information on
              our site.
            </span>
          </p>
          <p>
            <strong>&nbsp;Sharing</strong>
            <span>&nbsp;</span>
          </p>
          <p>
            <span>
              {" "}
              We may share aggregated demographic information with our partners.
              This is not linked to any personal information that can identify
              any individual person. We use an outside shipping company to ship
              orders, and a credit card processing company to bill users for
              goods and services. These companies do not retain, share, store or
              use personally identifiable information for any secondary purposes
              beyond filling your order.
            </span>
          </p>
          <p>
            <strong>&nbsp;</strong>
            <span>
              In our role as the promotion management for multiple sponsoring
              organizations, we employ hardware and software solutions,
              policies, procedures that act to greatly minimize&nbsp;the risk of
              accidental or intentional data breaches.
            </span>
          </p>
          <p>
            <strong>
              If you feel that we are not abiding by this privacy policy, you
              should contact us immediately via telephone at&nbsp;
              <u>516-620-0855</u> &nbsp; or &nbsp; <u>info@instaraise.com</u>.
            </strong>
          </p>
        </div>

        <div className={classes.close}>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() => toggleModal(false)}
          >
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
